import './styles.scss';
import {Card, Col, Container, Row} from "react-bootstrap";

function App() {
  return (
    <div className="App">

      <Card className={"AppHeaderCard"}>
        <Card.Header className={"AppHeader"}>
          <p className={"PageTitle"}>
            DELTA-INVEST 2000 Kft.
          </p>
          <img src={"EU_ERFA_corner.webp"} className="topRightCornerImage" alt="logo" width={200}/>
        </Card.Header>
        <Card.Body>
          <Card.Title>Támogatás</Card.Title>
          <Card.Text>
            - a kedvezményezett neve: DELTA-INVEST 2000 Ingatlanbefektetési Szolgáltató és Kereskedelmi Korlátolt
            Felelősségű Társaság<br/>
            a projekt címe: A Delta Invest 2000 Kft. fejlesztése<br/>
            - a szerződött támogatás összege: 14 668 875 Ft<br/>
            - a támogatás mértéke (%-ban): 36,31%<br/>
            - visszatérítendő hitel összege: 14 668 875 Ft<br/>
            - a hitel mértéke (%-ban): 36,31%<br/>
            - a projekt tartalmának bemutatása: A beruházás három elemet tartalmaz: hőszigetelés, amely során 15 cm
            vastagságú EPS hőszigetelő anyag került felhelyezésre a külső falakra, valamint 1 k értékű kétrétegű,
            műanyag nyílászárók kerültek elhelyezésre, és telepítésre került egy 50 kW-os napelemes rendszer. A
            beruházás révén jelentősen csökkeni fog a telephely energiafelhasználása, és mindenben meg fog felelni az
            épület a kor követelményeinek. A fejlesztés célja a meglévő áramfogyasztás kiváltása. A beruházás révén a
            vállalkozás költségeit optimalizálni tudja. A megmaradó összegeket pedig újabb fejlesztési célra tudja
            fordítani, ezzel a beruházás hozzájárul a vállalkozás hosszútávú fejlesztéséhez. A fejlesztés a vállalkozás
            gazdaságban betöltött szerepét javítja.<br/>
            - a projekt tényleges befejezési dátuma: 2020.05.31.<br/>
            - projekt azonosító száma: GINOP-4.1.1-8-4-4-16-2017-00013
          </Card.Text>
          <Card.Img src="tamogatas.png" style={{minWidth: "343px", width: "35vw"}}/>
          <Card.Footer className={"AppFooter"}>
            <div className={"companyData"}>
              <small className="text-muted">DELTA-INVEST 2000 Kft<br/>1211 Budapest, Mansfeld Péter út 27.
                sz.<br/>+ 36 20 956 73 13<br/>investdelta2000@gmail.com</small>
            </div>
            <img src={"1_infoblokk_2020_ERFA_3C.webp"} className="footerImages" alt="eu1" width={250}/>
            <img src={"SZ2020.webp"} className="footerImages" alt="eu2" width={250}/>
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  );
}

export default App;
